import { getRequestContext } from './request-context.js';
import { getOrCreateClientId } from './client-id.js';
export class AnalyticsClient {
    constructor(options) {
        this.options = options;
    }
    get collectorUrl() {
        return this.options.collectorUrl;
    }
    get clientId() {
        if (this.options.clientId) {
            return this.options.clientId;
        }
        return getOrCreateClientId();
    }
    createEvent(context) {
        return {
            page: location.href,
            title: document.title,
            context: {
                ...this.options.baseContext,
                ...context
            }
        };
    }
    sendPageView(context) {
        const pageView = this.createEvent(context);
        this.send({ page_views: [pageView] });
    }
    sendEvent(type, context) {
        const event = {
            ...this.createEvent(context),
            type
        };
        this.send({ events: [event] });
    }
    send({ page_views, events }) {
        const payload = {
            client_id: this.clientId,
            page_views,
            events,
            request_context: getRequestContext()
        };
        const body = JSON.stringify(payload);
        try {
            if (navigator.sendBeacon) {
                navigator.sendBeacon(this.collectorUrl, body);
                return;
            }
        }
        catch {
        }
        fetch(this.collectorUrl, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
            keepalive: false
        });
    }
}
